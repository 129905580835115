import React, { useEffect, useState } from 'react';

const REDIRECT_DELAY_SECONDS = 5;
const BASE_URL = 'https://app.ling-app.com';

const getBrowserLanguage = () => {
  const supportedLanguages = [
    'af',
    'am',
    'ar',
    'bg',
    'bn',
    'bs',
    'ca',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'et',
    'fa',
    'fi',
    'fil',
    'fr',
    'ga',
    'gu',
    'he',
    'hi',
    'hr',
    'hu',
    'hy',
    'id',
    'in',
    'it',
    'ja',
    'ka',
    'km',
    'kn',
    'ko',
    'lo',
    'lt',
    'lv',
    'ml',
    'mn',
    'mr',
    'ms',
    'my',
    'ne',
    'nl',
    'no',
    'pl',
    'ps',
    'pt',
    'pu',
    'ro',
    'ru',
    'sb',
    'sk',
    'sl',
    'sq',
    'sr',
    'sv',
    'sw',
    'ta',
    'te',
    'th',
    'tr',
    'uk',
    'ur',
    'vi',
    'yo',
    'yue',
    'zh',
    'zh-TW'
  ];
  const defaultLang = 'en';
  const browserLangs = navigator.languages || [
    navigator.language || navigator.userLanguage
  ];
  for (const lang of browserLangs) {
    if (supportedLanguages.includes(lang)) {
      return lang;
    }
    const primaryLang = lang.split('-')[0].toLowerCase();
    if (supportedLanguages.includes(primaryLang)) {
      return primaryLang;
    }
  }
  return defaultLang;
};

export const RedirectToNewLingWeb = () => {
  const [countdown, setCountdown] = useState(REDIRECT_DELAY_SECONDS);
  const [targetUrl, setTargetUrl] = useState('');

  useEffect(() => {
    const languageCode = getBrowserLanguage();
    const currentSearchParams = window.location.search;
    const fullTargetUrl = `${BASE_URL}/${languageCode}/onboarding${currentSearchParams}`;
    setTargetUrl(fullTargetUrl);

    const interval = setInterval(() => {
      setCountdown(prev => Math.max(prev - 1, 0));
    }, 1000);

    const timeout = setTimeout(() => {
      window.location.href = fullTargetUrl;
    }, REDIRECT_DELAY_SECONDS * 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <h2 style={styles.heading}>We&apos;ve moved to a new home!</h2>
        <p style={styles.text}>
          Our web app has a brand-new look and better performance — now live at:
        </p>
        <a href={targetUrl} style={styles.link}>
          {targetUrl}
        </a>
        <p style={styles.countdown}>
          Redirecting in <strong>{countdown}</strong> second
          {countdown !== 1 ? 's' : ''}...
        </p>
        <p style={styles.note}>
          Not redirected? Click{' '}
          <a href={targetUrl} style={styles.link}>
            here
          </a>
          .
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    backgroundColor: '#FFF8E1',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem'
  },
  box: {
    textAlign: 'center',
    maxWidth: '600px',
    backgroundColor: '#FFFFFF',
    padding: '2.5rem 3rem',
    borderRadius: '12px',
    boxShadow: '0 8px 25px rgba(255, 193, 7, 0.2)',
    border: '1px solid #FFECB3'
  },
  heading: { fontSize: '2rem', color: '#FF8F00', marginBottom: '1rem' },
  text: { fontSize: '1.1rem', color: '#555', margin: '1rem 0' },
  countdown: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#F57C00',
    marginTop: '1rem'
  },
  note: { fontSize: '0.95rem', color: '#888', marginTop: '1.5rem' },
  link: {
    color: '#FFA000',
    textDecoration: 'underline',
    fontWeight: 'bold',
    wordBreak: 'break-all'
  }
};

export default RedirectToNewLingWeb;
